import React from "react";
import "./Banner.css";
import "mdbreact/dist/css/mdb.css";
import FSD_Banner from "../../assets/fsd_banner.jpg";
import Carousel from "react-bootstrap/Carousel";
import FSD_Mern from "../../assets/fsd_mern.png";
import WhyUs from "../../assets/WhyUs.jpg";
import Mentor3 from "../../assets/Mentor_GS.jpg";

const Banner = () => {
    return (
        <div>
            <div
                style={{
                    margin: "20px 10rem 20px 5rem",
                    display: "flex",
                    borderRadius: "10px",
                }}
            >
                <Carousel
                    fade
                    controls={false}
                    indicators={false}
                    className="d-block w-100"
                >
                    <Carousel.Item interval={2500}>
                        <img
                            src={FSD_Mern}
                            alt="FSD MERN"
                            className="d-block w-100"
                            style={{
                                borderRadius: "20px",
                                objectFit: "cover", // Use cover to maintain aspect ratio
                                height: "600px", // Set a smaller uniform height
                                width: "100%", // Maintain full width
                            }}
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <img
                            className="d-block w-100"
                            src={FSD_Banner}
                            alt="FSD Banner"
                            style={{
                                borderRadius: "20px",
                                objectFit: "cover", // Use cover to maintain aspect ratio
                                height: "600px", // Set a smaller uniform height
                                width: "100%", // Maintain full width
                            }}
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <img
                            className="d-block w-100"
                            src={Mentor3}
                            alt="Mentors"
                            style={{
                                borderRadius: "20px",
                                objectFit: "cover", // Use cover to maintain aspect ratio
                                height: "600px", // Set a smaller uniform height
                                width: "100%", // Maintain full width
                            }}
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
            {/* New Image Below Carousel */}
            <div style={{ textAlign: "center", margin: "20px 0" }}>
                <img
                    src={WhyUs} // Update with your new image path
                    alt="Why Us"
                    style={{
                        borderRadius: "20px",
                        objectFit: "cover", // Use cover to maintain aspect ratio
                        width: "100%", // Maintain full width
                        maxWidth: "800px", // Set a smaller max width
                        height: "500px", // Set a smaller height
                    }}
                />
            </div>
        </div>
    );
};

export default Banner;