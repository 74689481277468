import axios from "axios";
import { toast } from "react-toastify";

const AddToCart = async (userId, courseItem) => {
    try {
    // Perform input validation
    if (!userId || !courseItem) {
      throw new Error("Invalid input data");
    }

    const cartDetails = await axios.post(process.env.REACT_APP_BACKEND_URL+'/addtocart', {
            userId,
            courseItem,
        });
        
        if (!cartDetails) {
            console.log("Item not added");
            return -1;
        } else {
            if (
                cartDetails.data.message === "Course already purchased" ||
                cartDetails.data.message === "Course already in cart"
            ) {
                toast.success(cartDetails.data.message);
                return 0;
            } else {
                toast.success(cartDetails.data.message);
                return 1;
            }
        }
    } catch (error) {
        console.error(error);
        toast.error("An error occurred");
    }
};

export { AddToCart };