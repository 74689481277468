// AboutUs.jsx

import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import './AboutUs.css'; // Import the corresponding CSS file
import Banner from '../../assets/blog_banner.jpg';
const AboutUs = () => {
  return (
    <MDBRow className="about-us-container">

      <header style={{ paddingLeft: 0 }}>
      <div
        className='p-5 text-center bg-image'
        style={{  marginLeft:'10px', backgroundImage:`url(${Banner})`, height: 600 }}
      >
        <div className='mask' style={{backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white'>
            
              <h1 className='mb-3'>About Us</h1>

             <MDBCardText tag="h6">
              Welcome to <strong>Geneus Solutions</strong>, We are integrated Technology and Elearning Firm specialized in providing Website design and development and Elearning courses.
              We are your premier destination for cutting-edge E-learning courses in full-stack development.
              Based in <strong>Bangalore, India </strong>, we are passionate about empowering individuals to become proficient and versatile developers in the ever-evolving field of technology.
            </MDBCardText><br/>
            <MDBCardText tag="h6">
              At <strong>Geneus Solutions</strong>, we offer comprehensive courses covering ReactJS, HTML/CSS/Javascript, Java, Angular  and the complete MERN stack.
              Our dynamic and hands-on approach ensures that students not only grasp the theoretical foundations but also gain practical skills that are essential in the real-world scenario.
            </MDBCardText>
            <br/>
            <MDBCardTitle tag="h2">Vision</MDBCardTitle>
            <MDBCardText tag="h6">
              To be a global leader in providing accessible and high-quality E-learning courses in full-stack development, fostering a community of skilled and innovative developers.
            </MDBCardText> <br/>

            <MDBCardTitle tag="h2">Mission</MDBCardTitle>
            <MDBCardText tag="h6">
              Our mission is to empower individuals worldwide by delivering exceptional E-learning experiences in full-stack development.
              We strive to bridge the gap between aspiring developers and industry demands, fostering a culture of continuous learning, collaboration, and excellence.
            </MDBCardText>
            </div>
          </div>
        </div>
      </div>
    </header>
      
    </MDBRow>
  );
};

export default AboutUs;
