import React from 'react';

const ThankYou = () => {
    return (
        <div className="thank-you-page">
            <h1>Thank You for Your Payment!</h1>
            <p>Your course will be activated within 24 hours.</p>
            <p>To start learning goto My Learning option on top right page</p>
            <p>Its recommended to use Laptop or Desktop while learning the course to get the best view and coding practices</p>
            <p>For any queries, kindly reach out to us at <a href="mailto:support@geneussolutions.in">support@geneussolutions.in</a></p>
            <p>Thank you and Happy Learning!</p>
        </div>
    );
};

export default ThankYou;