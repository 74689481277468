import React from 'react';
import './PrivacyPolicy.css'; // Import the corresponding CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Effective Date: 24-Jul-2024</p>

<p>
Geneus Solutions is committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website or use our services.
</p>
<p>
    <strong>
1. Information We Collect:<br/>
</strong>
1.1 Personal Information:
We may collect personal information, such as your name, email address, phone number, and other information you provide when you interact with our website, subscribe to our newsletter, or use our services.
<br/>
1.2 Usage Data:
We may collect non-personal information about your interactions with our website, including your IP address, browser type, pages visited, and other usage statistics. This information helps us improve our website and services.
</p>
<p>
    <br/>
    <strong>
2. How We Use Your Information:<br/>
</strong>
2.1 Providing Services:
We use your personal information to provide and improve our services, communicate with you, and respond to your inquiries.

2.2 Marketing:
With your consent, we may use your email address to send you newsletters, updates, and promotional materials. You can opt-out of receiving such communications at any time.

2.3 Analytics:
We use analytics tools to analyze website usage and improve our content and services.
</p>
<p>
    <br/>
    <strong>
3. Cookies and Similar Technologies:
</strong>
We may use cookies and similar technologies to enhance your browsing experience and collect information about how you use our website. You can manage your cookie preferences through your browser settings.
</p>
<p>
<strong>
4. Data Security:
</strong>
We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
</p>
<p>
<strong>
5. Third-Party Links:
</strong>
Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of such websites. We encourage you to review the privacy policies of any third-party sites you visit.
</p>
<p>
<strong>
6. Non-Refundable Purchases:
</strong>
 All purchases made are <strong>non-refundable</strong>. Once a purchase is completed and the course content is accessed or downloaded, refunds will not be provided under any circumstances. This policy applies to all courses, whether they are purchased individually or as part of a bundle.
</p>
<p>
<strong>
7. No Return Policy:
</strong>
As digital products, our courses are <strong>non-returnable</strong>. Once a purchase is made and the course content is accessed, it cannot be returned or exchanged for another course or product. We encourage you to review course descriptions, outlines, and sample content before making a purchase decision.
</p>
<p>
<strong>
8. Shipping and Delivery:
</strong>
Since our products are digital courses, there is no shipping involved. Upon successful completion of the purchase, you will receive instant access to the course materials via email or through your account on our website. If you encounter any issues with accessing the course content, please contact our customer support team at <a href="mailto:support@geneussolutions.in">support@geneussolutions.in</a> for assistance.
</p>
<p>
<strong>
9. Terms and Conditions:
</strong>
By purchasing and accessing courses on https://www.geneussolutions.in, you agree to abide by the terms and conditions outlined below:
        <ul>
          <li>Course materials provided on https://www.geneussolutions.in are for personal use only and may not be shared, distributed, or reproduced without prior consent.</li>
          <li>Unauthorized use of course materials may result in legal action.</li>
          <li>https://www.geneussolutions.in reserves the right to modify course content, pricing, or policies without prior notice.</li>
          <li>Access to courses may be subject to account verification and compliance with our user guidelines.</li>
          <li>https://www.geneussolutions.in is not liable for any loss or damage resulting from the use of course materials or inability to access the website.</li>
        </ul>
</p>

<p>
<strong>
10. Children's Privacy:
</strong>
Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us to request its deletion.
</p>

<p>
<strong>
11. Changes to this Privacy Policy:
</strong>
We may update this Privacy Policy from time to time. Any changes will be effective when posted on our website. We encourage you to review this Privacy Policy periodically.
</p>
<p>
<strong>
12. Contact Us:
</strong>
If you have any questions or concerns about our Privacy Policy or the use of your personal information, please contact us at support@geneussolutions.in
Address: S104, Geneus Solutions, Kaikondrahalli, Sarjapur road, Bangalore 560035
</p>
    </div>
  );
};

export default PrivacyPolicy;
