import axios from "axios";
import { toast } from "react-toastify";

const RemoveFromCart = async (cartId, cartItemId, userId) => {
    try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/cartdelete`;
        const params = {
          user_id: userId,
          course_id: cartItemId,
          cart_id: cartId
        };
        const cartDetails = await axios.post(url, params);
        if (!cartDetails) {
            console.log("item not deleted");
        } else {
            toast.success("Course deleted from cart");
        }
    } catch (error) {
        toast.error("An error occurred");
    }
};

const emptyCart = async (cartId) => {
    try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/cartempty`;
    const params = {
      cart_id: cartId
    };
    const cartDetails = await axios.post(url, params);
        if (!cartDetails) {
            console.log("item not deleted");
        } /*else {
            toast.success("Cart Emptied");
        }*/
    } catch (error) {
        toast.error("An error occurred");
    }
};

export { RemoveFromCart, emptyCart };