import React, { useEffect, useState } from 'react';

const VisitorInfo = () => {
    const [ip, setIp] = useState('');
    const [city, setCity] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchVisitorData = async () => {
            try {
                const response = await fetch('http://ip-api.com/json/');
                const data = await response.json();

                if (data.status === 'success') {
                    setIp(data.query); // IP address
                    setCity(data.city); // City

                    // Send visitor data to the backend
                    await saveVisitorData(data.query, data.city);
                } else {
                    setError('Unable to fetch location data');
                }
            } catch (err) {
                setError('Error fetching data');
                console.error(err);
            }
        };

        const saveVisitorData = async (ip, city) => {
            try {
                await fetch(process.env.REACT_APP_BACKEND_URL+'/api/visitors', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ip, city }),
                });
            } catch (err) {
                console.error('Error saving visitor data:', err);
            }
        };

        fetchVisitorData();
    }, []);

    return (
        <div>
            <h1>Visitor Information</h1>
            {error && <p>{error}</p>}
            <p><strong>IP Address:</strong> {ip}</p>
            <p><strong>City:</strong> {city}</p>
        </div>
    );
};

export default VisitorInfo;