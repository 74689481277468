
import React from "react";

const PaymentButton = () => {
  return (
    <div className="razorpay-embed-btn" data-url="https://pages.razorpay.com/pl_NVqW2IRIr4B8IE/view" data-text="Pay Now" data-color="#528FF0" data-size="large">
      <script>
        {(() => {
          const d = document;
          const x = !d.getElementById("razorpay-embed-btn-js");
          if (x) {
            const s = d.createElement("script");
            s.defer = true;
            s.id = "razorpay-embed-btn-js";
            s.src = "https://cdn.razorpay.com/static/embed_btn/bundle.js";
            d.body.appendChild(s);
          } else {
            const rzp = window["__rzp__"];
            rzp && rzp.init && rzp.init();
          }
        })()}
      </script>
    </div>
  );
};

export default PaymentButton;