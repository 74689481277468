import React, { useState } from 'react';
import { MDBIcon } from 'mdbreact';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { reset } from '../../redux/slices/cartCount';
import './Contact.css';
import {  useNavigate } from "react-router-dom";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/contact`,
        { name, email, contact, message }
      );
      dispatch(reset());
      toast.success('Your query has been submitted. One of our representative will reachout to you in 24hrs!');
      navigate("/");
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.error);
      alert("Error occurred while submitting request. If problem persists kindly reach out to us via support number or email ");
    }
  };

  return (
    <div className="contact_us_green">
      <div className="responsive-container-block big-container">
        <div className="responsive-container-block container">
          <div className="form-container">
            <form onSubmit={handleSubmit} className="form-box">
              <div className="form-wrapper">
                <input
                  className="input"
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  placeholder="Name"
                  value={name}
                />
                <input
                  className="input"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  placeholder="Email"
                  value={email}
                />
                <input
                  className="input"
                  onChange={(e) => setContact(e.target.value)}
                  name="contact"
                  type="number"
                  placeholder="Contact No."
                  value={contact}
                />
                <textarea
                  className="textinput"
                  onChange={(e) => setMessage(e.target.value)}
                  name="message"
                  placeholder="Please post your query here"
                  value={message}
                />
                <button className="submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="contact-info-container">
            <div className="contact-info">
              <div className="text-content">
                <p className="contactus-head">Contact Information</p>
              </div>
              <div className="contact-details">
                <div className="contact-item">
                  <MDBIcon icon="envelope" size="2x" />
                  <p className="contact-text">support@geneussolutions.in</p>
                </div>
                <div className="contact-item">
                  <MDBIcon icon="phone" size="2x" />
                  <p className="contact-text">+91 9148950239</p>
                </div>
              </div>
              <br/>
              <div className="follow-us">
                <h3>Follow Us</h3>
                <div className="social-media-links">
                  <a href="https://www.facebook.com/geneus.solutions">
                    <MDBIcon fab icon="facebook" size="2x" />
                  </a>
                  <a href="https://www.instagram.com/geneus.solutions/">
                    <MDBIcon fab icon="instagram" size="2x" />
                  </a>
                  <a href="https://www.linkedin.com/company/geneus-solutions">
                    <MDBIcon fab icon="linkedin" size="2x" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;